var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"mr-4"},[_c('trac-back-button',[_vm._v("Back")]),(_vm.isLoading)?_c('trac-loading'):_vm._e(),_c('h3',{staticClass:"my-8 font-bold text-sm"},[_vm._v("KYC (Manage Wallet PIN)")]),_c('div',{staticClass:"shadow-lg rounded-md p-4 pt-8 md:w-9/12 mx-auto my-8 md:px-12"},[_c('h3',{staticClass:"my-5 font-bold text-primaryBlue"},[_vm._v("Manage Wallet PIN")]),_c('div',{staticClass:"grid md:grid-cols-3 md:mx-10 my-12 gap-4"},[(_vm.walletId)?_c('div',{staticClass:"shadow-md rounded-md p-5 cursor-pointer hover:bg-accentLight flex items-center justify-between gap-3",on:{"click":_vm.gotoConfirmPasswordPage}},[_vm._m(0),_c('img',{attrs:{"src":require("./../../../assets/svg/pin-management/chevron-icon-right.svg"),"alt":""}})]):_c('div',{staticClass:"shadow-md rounded-md p-5 cursor-pointer hover:bg-accentLight flex items-center justify-between gap-3",on:{"click":function($event){return _vm.$router.push(_vm.PinPage)}}},[_vm._m(1),_c('img',{attrs:{"src":require("./../../../assets/svg/pin-management/chevron-icon-right.svg"),"alt":""}})]),(_vm.walletId)?_c('div',{staticClass:"shadow-md rounded-md p-5 cursor-pointer hover:bg-accentLight flex items-center justify-between gap-3",on:{"click":function($event){return _vm.$router.push({
            name: 'ConfirmPassword',
            query: {
              ops: 'forgot_wallet_pin',
            },
          })}}},[_vm._m(2),_c('img',{attrs:{"src":require("./../../../assets/svg/pin-management/chevron-icon-right.svg"),"alt":""}})]):_vm._e()])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-3"},[_c('img',{attrs:{"src":require("./../../../assets/svg/pin-management/settlement-pref.svg"),"alt":""}}),_c('p',{staticClass:"text-xs"},[_vm._v("Change Wallet Pin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-3"},[_c('img',{attrs:{"src":require("./../../../assets/svg/pin-management/settlement-pref.svg"),"alt":""}}),_c('p',{staticClass:"text-xs"},[_vm._v("Create Wallet Pin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-3"},[_c('img',{attrs:{"src":require("./../../../assets/svg/pin-management/settlement-pref.svg"),"alt":""}}),_c('p',{staticClass:"text-xs"},[_vm._v("Forgot Wallet Pin")])])
}]

export { render, staticRenderFns }